import React from 'react'
import '../../assets/css/style.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ProductInfo from './ProductInfo'

const ProductPage = () => {
  return (
    <>
      <ProductInfo />
    </>
  )
}
export default ProductPage
